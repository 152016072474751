@import "legacy/branding";
.jumbotron {
    padding: 0;
    width: 100%;
    height: 500px;
    overflow: hidden;
    z-index: -200;
    margin: 0;
    @media (min-width:1200px) {
        height:700px;
    }
    .jumbotron-overlay {
        position: absolute;
        z-index: 100;
        width: 100%;
        height: 500px;
        display: table;
        .container {
            margin-top: 64px;
            @media (min-width:1200px) {
                margin-top:164px;
            }
        }
    }
    .jumbotron-bkg {
        position: relative;
        height: 100%;
        width: 100%;
        video {
            position: absolute;
            left: 50%;
            top: 50%;
            bottom: auto;
            right: auto;
            transform: translateX(-50%) translateY(-50%);
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            z-index: 0;
        }
        &.img {background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('/images/page-images/homepage-hero/web-header.jpg');}
    }
}

.confirmation {
    img {
        width: 100px;
        margin: auto;
        @media (min-width: 768px) {
            width: 300px;
        }
    }
    h1 {
        color: $brandcolor1;
    }
    h3 {
        color: initial;
        font-size: 18px;
        @media (min-width: 768px) {
            font-size: 24px;
        }
        span {
            color: $brandcolor1;
            font-weight: bold;
            border-bottom: 1px dotted $brandcolor1;
        }
    }
    .buttons {
        margin-top: 40px;
        .btn-primary {
            border-radius: 0;
            background: none;
            border-color: $brandcolor3;
            color: $brandcolor3;
            margin-right: 10px;
            transform: background 0.5s ease;
            &:hover, &:focus {
                background: $brandcolor3;
                color: white;
            }
        }
    } 
}