@import "abstracts/variables";

$purple: $brandcolor1;
$midPurple: $brandcolor1-light;
$ltPurple: $brandcolor1-light;
$dkPurple: $brandcolor1-dark;
$grey: $gray-light1;
$midGrey: $gray-mid3;
$darkGrey: $gray-dark1;
$green: $brandcolor2;
$dkGreen: $brandcolor2-dark;
$orange: $brandcolor3;
$ltOrange: $brandcolor3-light;
$dkOrange: $brandcolor3-dark;